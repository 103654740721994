import React from "react";

const PrivacyPolicy = () => {
    const handleBackToLanding = () => {
        window.location.href = "/"; // Redirects to the landing page
      };
      
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> December 19, 2024 </p>

      <h2>1. Data We Collect</h2>
      <p>We collect the following data from your Spotify account when you use YourFestivalPlaylist:</p>
      <ul>
        <li>Your liked songs.</li>
        <li>Your most listened-to tracks (short-term, medium-term, and long-term).</li>
        <li>Songs from your playlists.</li>
      </ul>

      <h2>2. How We Use Your Data</h2>
      <p>
        The collected data is used solely to create personalized playlists that match the artists in a selected
        festival. We do not store, share, or sell your data.
      </p>

      <h2>3. Data Retention</h2>
      <p>
        - All data is processed temporarily during your session. <br />
        - Once the playlist is generated, your data is not retained.
      </p>

      <h2>4. Data Sharing</h2>
      <p>Your data is not shared with any third parties.</p>

      <h2>5. Your Rights</h2>
      <ul>
        <li>Disconnect your Spotify account at any time through the app.</li>
        <li>Request deletion of your session data by logging out.</li>
      </ul>

      <h2>6. Contact Us</h2>
      <p>
        If you have questions about this Privacy Policy, please contact us at mmarcosfrech@gmail.com .
      </p>

      <button 
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#e64a19",
          color: "#FFFFFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px",
        }}
        onClick={handleBackToLanding}
      >
        Back to Landing Page
      </button>

    </div>
  );
};

export default PrivacyPolicy;
