import React, { useState, useEffect } from 'react';
import LoginButton from './components/LoginButton';
import { ReactComponent as LogoBlack } from './Full_Logo_Black_CMYK.svg';
import { ReactComponent as LogoWhite } from './Full_Logo_White_CMYK.svg';
import { ReactComponent as LogoGreen } from './Full_Logo_Green_CMYK.svg';
import { ReactComponent as SmallLogoBlack } from './Primary_Logo_Black_CMYK.svg';
import { ReactComponent as SmallLogoWhite } from './Primary_Logo_White_CMYK.svg';
import logo from './Full_Logo_White_CMYK.svg';
import { Button, CircularProgress, List, ListItem, ListItemText, Typography, Divider, Container,
  createTheme,
  ThemeProvider,
  LinearProgress,
  CssBaseline, } from '@mui/material';
import Cookies from 'js-cookie';
import './App.css';



const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ff7300",
    },
    background: {
      default: "#121212",
      paper: "#181818",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B3B3B3",
    },
  },
  typography: {
    fontFamily: "'Circular', 'Helvetica Neue', 'Arial', sans-serif",
  },
});


function App() {
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [accessToken, setAccessToken] = useState(null);
  const [matchedTracks, setMatchedTracks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [playlistUrl, setPlaylistUrl] = useState('');
  const [festivalId, setFestivalId] = useState(null);
  const [playlistName, setPlaylistName] = useState(null);
  const [progress, setProgress] = useState(0); // State for determinate progress
  const [disconnect, setDisconnect] = useState(false); // State for determinate progress


  useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const lp = urlParams.get('disconnect');  // Retrieve 'id' from query parameter
      if(lp){
        setAccessToken(null)
        console.log(accessToken)
      }
    }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;  // Increment by 1 for smooth movement
        }
        clearInterval(interval); // Stop when reaching 100
        return 100;
      });
    }, 250); // Adjust time interval for smoother/faster transitions

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      getAccessToken(code);

      // Remove `code` from the URL without reloading the page
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  useEffect(() => {
    if (accessToken && festivalId) {
      getMatchedTracks();
    }
  }, [accessToken, festivalId]);

  const handleDisconnect = () => {
    window.location.href = `/?disconnect=true`; // Or your desired route, e.g. '/app' or wherever the app starts
  };
  
  const getAccessToken = async (code) => {
    try {

      const response = await fetch(`${REACT_APP_BACKEND_URL}/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch access token: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      const { accessToken, festivalId } = data;
      setAccessToken(accessToken);
      setFestivalId(festivalId.festivalId);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const getMatchedTracks = async () => {
    setLoading(true);
    setError('');
    if (!accessToken) return;

    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/matched-tracks/${festivalId}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Failed to fetch matched tracks');
      const data = await response.json();
      setMatchedTracks(data.matchedTracks);
      setPlaylistName(data.playlistName)

    } catch (error) {
      setError('Error fetching matched tracks');
    }
    setLoading(false);
  };

  const createPlaylist = async () => {
    if (!accessToken) return;
    setLoading(true);

    const matchedTracksUris = matchedTracks.map((track) => track.uri);

    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/create-playlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: 'include',
        body: JSON.stringify({ matchedTracksUris, playlistName }),
      });

      if (!response.ok) throw new Error('Failed to create playlist');
      const data = await response.json();
      setPlaylistUrl(data.playlistId);
      const playlistUrlApp = `spotify://playlist/${data.playlistId}`;
      const playlistUrlWeb = `https://open.spotify.com/playlist/${data.playlistId}`;

      window.location.href = playlistUrlApp;


      setTimeout(() => {
        window.location.href = playlistUrlWeb;
      }, 7000);


    } catch (error) {
      setError('Error creating playlist');
    }
    setLoading(false);
  };

  return (
    <div className="App" style={{ padding: '00px' }}>
      {!accessToken || !festivalId ? (
        <LoginButton />
      ) : (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline /> {/* Ensures the dark theme applies to the entire page */}
          <div className="app-page-logo">
          </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          
        </div>
        </ThemeProvider>

      )}

      {loading && <ThemeProvider theme={darkTheme}>
  <CssBaseline />
  <div className="app-page-logo">
          <LogoWhite/>
          <p style={{ fontSize: '14px', color: '#fff' }}>Powered by Spotify</p>
          </div>
  <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full-screen height for centering
        textAlign: 'center',
        position: 'relative',
        top: '-20px', // Moves the entire container slightly upward
        width: '100%', // Ensures the full width is used for alignment
      }}
    >
      {/* Progress Bar */}
      <LinearProgress 
        variant="determinate" 
        value={progress} 
        style={{
          width: '80%', // Adjust width as needed, keeping it smaller than 100% for better look
          backgroundColor: '#333', // Lighter background for visibility
          color: '#fffff', // Spotify green color
          height: '12px',
        }} 
      />
      
      {/* Text */}
      <Typography
        variant="h6"
        style={{
          marginTop: '10px',
          width: '80%', // Same width as the progress bar to align properly
        }}
      >
        {progress === 100 
          ? "Your playlist is almost ready" 
          : "Wait while we find your tracks for the festival..."}
      </Typography>
    </div>
</ThemeProvider>
    }
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {matchedTracks.length > 0 && !loading && (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline /> {/* Ensures the dark theme applies to the entire page */}
          <header className="header">
            <div className="login-logo">
              <img src={logo} alt="Logo" style={{ width: "300px", height: "auto" }} />
              <p style={{ fontSize: '14px', color: '#fff' }}>Powered by Spotify</p>
            </div>
          </header>
          <button className="login-disconnect-button" onClick={handleDisconnect}>
        Disconnect
            </button>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "175px"}}>
         <Button
            variant="contained"
            color="primary"
            onClick={createPlaylist}
            sx={{ marginBottom: '20px',
              padding: '16px 32px',  
             }}
            disabled={loading || matchedTracks.length === 0}
          >
            Click Here to Create Playlist
          </Button>
          </div>
        <div style={{ marginTop: '20px', padding: '20px', maxWidth: '600px', margin: 'auto' }}>
        <Typography variant="h5" gutterBottom>
          Matched Tracks
        </Typography>
        <List>
          {matchedTracks.map((track, index) => (
            <React.Fragment key={index}>
              <ListItem>
              <Button
                    component="a"
                    href={track.spotifyUrl} // Spotify URL for the track
                    target="_blank"
                    fullWidth
                    style={{
                      textAlign: 'left', // Align the text to the left
                      padding: '10px',
                      margin: '5px 0',
                    }}
                  >
                <div
                  style={{
                  width: '30px', // Adjust the width of the logo
                  height: '30px', // Adjust the height of the logo
                  marginRight: '10px', // Add spacing between the logo and text
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >   
                  <SmallLogoWhite style={{ width: '100%', height: '100%' }} />
                </div>

                <ListItemText
                  primary={
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {track.name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {track.artists.join(', ')}
                    </Typography>
                  }
                />
                  </Button>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </div>
      </ThemeProvider>

      )}

      {playlistUrl && (
        <div style={{ marginTop: '20px' }}>
          <h3>Playlist Created!</h3>
          <a href={playlistUrl} target="_blank" rel="noopener noreferrer">
            View Your Playlist on Spotify
          </a>
        </div>
      )}
    </div>
  );
}

export default App;
