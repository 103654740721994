// LandingPage.js
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as LogoBlack } from '../Full_Logo_Black_CMYK.svg';
import { ReactComponent as LogoWhite } from '../Full_Logo_White_CMYK.svg';
import { ReactComponent as LogoGreen } from '../Full_Logo_Green_CMYK.svg';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Disconnect from './Disconnect';
import { Button, Box, Typography } from '@mui/material';
import './LandingPage.css';


const LandingPage = () => {

  const [privacy, setPrivacy]  = useState(null)
    const [terms, setTerms]  = useState(null)
    const [disconnect, setDisconnect]  = useState(null)
  
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const lp = urlParams.get('privacy-policy');  // Retrieve 'id' from query parameter
      setPrivacy(lp)
  
    }, [privacy]);

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const lp = urlParams.get('terms-of-service');  // Retrieve 'id' from query parameter
      setTerms(lp)
  
    }, [terms]);

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const lp = urlParams.get('disconnect');  // Retrieve 'id' from query parameter
      setDisconnect(lp)
  
    }, [disconnect]);
    
  const handleNavigate = () => {
    window.location.href = `/?landingP=true`; // Or your desired route, e.g. '/app' or wherever the app starts
  };

  const handleDisconnect = () => {
    window.location.href = `/?disconnect=true`; // Or your desired route, e.g. '/app' or wherever the app starts
  };

  return (
     <div>
      {privacy ? (
            <PrivacyPolicy />
          ) : terms ? (
            <TermsOfService />
          ) : disconnect ? (
            <Disconnect />
          ) : (

      <div className="landing-page">    
      <div className="landing-page-overlay"></div>
      <header className="header">
        <div className="landing-page-logo">
          <LogoWhite />
          <p style={{ fontSize: '14px', color: '#fff' }}>Powered by Spotify</p>
        </div>
      </header>
      <button className="landing-page-disconnect-button" onClick={handleDisconnect}>
    Disconnect
        </button>
<div className="landing-page-content">
        <h1 className="landing-page-title">
        Create Festival Playlists from Your Favorite Music
        </h1>
        <p className="landing-page-subtitle">
        Discover songs by your favorite artists performing at upcoming festivals. Your playlists are created using your Spotify account, powered by Spotify’s API.
        </p>
        <button className="landing-page-button" onClick={handleNavigate}>
          Explore Festivals
        </button>
      </div>
      <footer className="landing-page-footer">
        <p>
          <a href="/?privacy-policy=true" className="footer-link">Privacy Policy</a> |{' '}
          <a href="/?terms-of-service=true" className="footer-link">Terms of Service</a>
        </p>
        <p>
    Your Festival Playlist is an independent app and is not endorsed by Spotify or any artist, record label, or sound recording.
  </p>
      </footer>
      </div>
          )}
    </div>
  );
};

export default LandingPage;
