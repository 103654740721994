import React from "react";

const TermsOfService = () => {

    const handleBackToLanding = () => {
        window.location.href = "/"; // Redirects to the landing page
      };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Terms of Service</h1>
      <p><strong>Effective Date:</strong> December 19, 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By using YourFestivalPlaylist, you agree to these Terms of Service.</p>

      <h2>2. Description of Service</h2>
      <p>
        YourFestivalPlaylist connects to your Spotify account to create personalized playlists based on your music
        preferences and a selected festival.
      </p>

      <h2>3. User Responsibilities</h2>
      <ul>
        <li>You must have a valid Spotify account to use the app.</li>
        <li>You agree to use the app in compliance with Spotify’s terms of use.</li>
      </ul>

      <h2>4. Disclaimer</h2>
      <p>
        - YourFestivalPlaylist is not affiliated with Spotify. <br />
        - The app is provided "as is," and we are not liable for any issues arising from its use.
      </p>

      <h2>5. Changes to Terms</h2>
      <p>
        We may update these Terms of Service from time to time. The latest version will always be available on our
        website.
      </p>

      <h2>6. Contact Us</h2>
      <p>For questions about these Terms, please email us at mmarcosfrech@gmail.com .</p>

      <button 
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#e64a19",
          color: "#FFFFFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px",
        }}
        onClick={handleBackToLanding}
      >
        Back to Landing Page
      </button>
    </div>
  );
};

export default TermsOfService;
