import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as LogoBlack } from '../Full_Logo_Black_CMYK.svg';
import logo from '../Full_Logo_White_CMYK.svg';
import { ReactComponent as LogoGreen } from '../Full_Logo_Green_CMYK.svg';
import { ReactComponent as SmallLogoBlack } from '../Primary_Logo_Black_CMYK.svg';

import {
  Container,
  LinearProgress,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  List,
  ListItem,
  Button,
  createTheme,
  ThemeProvider,
  CssBaseline,
} from "@mui/material";
import _ from 'lodash'; // Install lodash if not already done
import LandingPage from './LandingPage';
import './LoginButton.css';
import axios from 'axios';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ff7300", // Spotify green
    },
    background: {
      default: "#121212", // Background dark color
      paper: "#181818",   // Paper background color
    },
    text: {
      primary: "#FFFFFF", // White text
      secondary: "#B3B3B3", // Light grey for secondary text
    },
  },
  typography: {
    fontFamily: "'Circular', 'Helvetica Neue', 'Arial', sans-serif",
  },
});



const LoginButton = () => {
  const REACT_APP_BACKEND_URL= process.env.REACT_APP_BACKEND_URL
  const [festivals, setFestivals] = useState([]); // Store fetched festivals
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [page, setPage] = useState(1); // Current page for pagination
  const [hasMore, setHasMore] = useState(true); // Check if more data is available
  const [search, setSearch] = useState(''); // Search input state
  const [landingP, setLandingP]  = useState(null)
  const festivalListRef = useRef(null);  // Create ref for the festival list container

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lp = urlParams.get('landingP');  // Retrieve 'id' from query parameter
    setLandingP(lp)

  }, [landingP]);
  
  useEffect(() => {
    const fetchFestivals = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${REACT_APP_BACKEND_URL}/festivals`, {
          params: { page, limit: 50, search }, // Add pagination and search params
          withCredentials: true, 
        });
        if (page === 1) {
          setFestivals(response.data.festivals);
        } else {
          setFestivals((prev) => [...prev, ...response.data.festivals]);
        }
        setHasMore(response.data.festivals.length > 0); // Stop fetching if no more festivals
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch festivals');
        setLoading(false);
      }
    };
    
    fetchFestivals();
  }, [page, search]);

 


  // Infinite scrolling handler
  const handleScroll = (e) => {
    const container = e.target;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
  
    console.log('scrollTop:', scrollTop, 'scrollHeight:', scrollHeight, 'clientHeight:', clientHeight);
  
    // Check if the user is near the bottom of the container
    if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore) {
      console.log('Fetching more data...');
      setPage((prevPage) => prevPage + 1); // Load more data
    }
  };

  // Attach and detach scroll event listener
  useEffect(() => {
    if (festivalListRef.current) {
      const throttledScroll = _.throttle(handleScroll, 200);
      festivalListRef.current.addEventListener('scroll', throttledScroll);

      // Cleanup the event listener when the component is unmounted or updated
      return () => {
        festivalListRef.current.removeEventListener('scroll', throttledScroll);
      };
    }
  }, [hasMore]);

  // Search input handler with debounce
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to first page for new search
  };
  
  const handleLogin = (festivalId) => {
    window.location.href = `${REACT_APP_BACKEND_URL}/login?festivalId=${festivalId}`;
  };

  const handleDisconnect = () => {
    window.location.href = `/?disconnect=true`; // Or your desired route, e.g. '/app' or wherever the app starts
  };

  return (
    <div className="LoginButton" style={{ padding: '0px' }}>
    
    {!landingP ? (
      <LandingPage />
    ) : (
      <div className="login-page">
      <ThemeProvider theme={darkTheme}>
    <CssBaseline /> {/* Ensures the dark theme applies to the entire page */}
    <header className="header">
            <div className="login-logo">
              <img src={logo} alt="Logo" style={{ width: "300px", height: "auto" }} />
              <p style={{ fontSize: '14px', color: '#fff' }}>Powered by Spotify</p>
            </div>
          </header>
          <button className="login-disconnect-button" onClick={handleDisconnect}>
        Disconnect
            </button>

      <div className="login-page-overlay"></div>
      <div className="login-page-content">

        <h1 className="login-page-title">
            Festival Playlist Creator
          </h1>

           {/*  <p className="login-page-subtitle">
            Easily create your personalized festival playlist with just a few clicks.
          </p>*/} 
          <TextField
            variant="outlined"
            label="Search Festivals"
            value={search}
            onChange={handleSearch}
            InputProps={{
              style: { color: "#FFFFFF" },
            }}
            InputLabelProps={{
              style: { color: "#B3B3B3" },
            }}
            sx={{
              backgroundColor: "#181818",
              borderRadius: "5px",
              width: "400px",
              '@media (max-width: 600px)': {
      width: "90%", // Adjust width for mobile
    },
            }}
            
          />
          {loading && page === 1 ? (
             <div style={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress variant="determinate" color="primary" />
            </div>
          ) : error ? (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          ) : (
            <div>
              <p className="login-page-subtitle">
                Festivals
              </p> 
              <div className="festival-list-container"
                ref={festivalListRef} // Attach the scroll handler
              >

              <List>
                {festivals.map((festival) => (
                  <ListItem key={festival._id} className="festival-list-item">
                    <button className="login-page-button"
                      onClick={() => {
                        handleLogin(festival._id);// This will set landingP to true
                      }}
                    >
                      {festival.name}
                    </button>
                  </ListItem>
                ))}
              </List>
              {loading && page > 1 && <CircularProgress color="primary" />}
              {!hasMore && (
                <Typography variant="subtitle2" color="textSecondary">
                  No more festivals to show
                </Typography>
              )}
            </div>
            </div>
          )}
      </div>

  </ThemeProvider>
  </div>
    )}

  </div>

  );
};

export default LoginButton;
